import React from "react"
import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Typography } from "../Typography/Typography"
import { PlainLink } from "../PlainLink/PlainLink"

interface BlogSummaryCardProps {
  category: string
  title: string
  description: string
  link: string
  coverImage: IGatsbyImageData
  darkBlueBg?: boolean
}

export const BlogSummaryCard: React.FC<BlogSummaryCardProps> = ({
  category,
  title,
  description,
  link,
  coverImage,
  darkBlueBg,
}) => {
  return (
    <PlainLink to={link}>
      <Card darkblueBg={darkBlueBg}>
        <ImageContainer>
          <CoverImage image={coverImage} alt={title} />
        </ImageContainer>
        <Container>
          <Category variant="caption">{category}</Category>
          <Title variant="h5">{title}</Title>
          <Description variant="p">{description}</Description>
        </Container>
      </Card>
    </PlainLink>
  )
}

const Card = styled.div<{ darkblueBg?: boolean }>(({ theme, darkblueBg }) => ({
  boxShadow: darkblueBg ? theme.boxShadow.darkblue : theme.boxShadow.light,
  borderRadius: "8px",
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  background: "#fff",
}))

const ImageContainer = styled.div({
  width: "100%",
  height: "300px",
})

const CoverImage = styled(GatsbyImage)({
  height: "100%",
  width: "100%",
})

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  gap: "1em",
  maxWidth: "400px",
})

const Category = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  lineHeight: "32px",
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  lineHeight: "32px",
}))

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  lineHeight: "24px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  lineClamp: 3,
  WebkitBoxOrient: "vertical",
}))
