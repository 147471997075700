import styled from "@emotion/styled"
import React from "react"
import { sharingSources } from "./sharePost.utils"

interface SharePostProps {
  postUrl: string
  title: string
}
export const SharePost: React.FC<SharePostProps> = ({ postUrl, title }) => {
  return (
    <SharePostContainer>
      {sharingSources.map((source) => {
        return (
          <a
            key={source.name}
            href={source.urlGenerator(postUrl, title)}
            target="_blank"
          >
            <source.icon />
          </a>
        )
      })}
    </SharePostContainer>
  )
}

const SharePostContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
})
