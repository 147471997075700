import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import { CaseStudy } from "../FeaturedCaseStudies/FeaturedCaseStudies"

export const getLandingImage = (landingImage: ImageDataLike) => {
  const processedImage = process.env.STORYBOOK
    ? (landingImage as IGatsbyImageData)
    : getImage(landingImage)
  if (!processedImage) {
    throw new Error("Could not find image")
  }

  return processedImage
}

export const getCaseStudies = (caseStudies: CaseStudy[]) => {
  return caseStudies.map(({ coverImage, ...other }) => {
    const image: IGatsbyImageData | undefined = process.env.STORYBOOK
      ? (coverImage as IGatsbyImageData)
      : getImage(coverImage)

    if (!image) {
      throw new Error(`No image found for ${coverImage}`)
    }

    return { ...other, coverImage: image }
  })
}

export const getServiceCards = (
  serviceCards: Array<{
    icon: ImageDataLike
    title: string
    description: string
    link: string
    linkText: string
  }>
) => {
  return serviceCards.map(({ icon, ...others }) => {
    const image = process.env.STORYBOOK
      ? (icon as IGatsbyImageData)
      : getImage(icon)
    if (!image) {
      throw new Error(`No image for ${others.title}`)
    }

    return { ...others, icon: image }
  })
}

export const getInsights = (
  insights: Array<{
    title: string
    category: string
    summary: string
    featuredImage: ImageDataLike
  }>
) => {
  return insights.map((insight) => {
    const featuredImage = process.env.STORYBOOK
      ? (insight.featuredImage as IGatsbyImageData)
      : getImage(insight.featuredImage)

    if (!featuredImage) {
      throw new Error(`Could not find image for ${insight.title}`)
    }

    return {
      ...insight,
      featuredImage,
    }
  })
}
