import styled from "@emotion/styled"
import { PageProps } from "gatsby"
import { GatsbyImage, getSrc, ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"
import { BlogSummaryCard } from "../components/BlogSummary/BlogSummaryCard"
import { insightMenuItems } from "../components/InsightsMenu/insightsMenu.utils"
import { Layout } from "../components/Layout/Layout"
import { BasePageData } from "../components/Layout/layout.hocs"
import {
  getFooterProps,
  getHeaderProps,
} from "../components/Layout/layout.utils"
import { NewsletterCard } from "../components/NewsletterCard/NewsletterCard"
import { Paper } from "../components/Paper/Paper"
import { PostMenu } from "../components/PostMenu/PostMenu"
import { SharePost } from "../components/SharePost/SharePost"
import { Typography } from "../components/Typography/Typography"
import { breakpoint } from "../theme/theme"
import { getSlugFromTitle } from "../utils/slug"
import {
  code_block_styles,
  getProcessedImage,
  getProcessedInsights,
} from "./blogPost.utils"

interface BlogPostData extends BasePageData {
  insight: {
    frontmatter: {
      title: string
      category: string
      summary: string
      author: {
        name: string
        title: string
        link: string
      }
      featuredImage: ImageDataLike
    }
    internal: {
      content: string
    }
  }
  blogDetail: {
    frontmatter: {
      moreInsights: {
        title: string
      }
      newsletter: {
        sideImage: ImageDataLike
      }
    }
  }
  insights: {
    nodes: Array<{
      frontmatter: {
        title: string
        category: string
        summary: string
        featuredImage: ImageDataLike
      }
    }>
  }
}

type BlogPostProps = PageProps<BlogPostData>

export const BlogPost: React.FC<BlogPostProps> = (props) => {
  const { title, category, author, featuredImage, summary } =
    props.data.insight.frontmatter
  const siteData = props.data.site.siteMetadata
  const headerProps = props.data.header
  const footerProps = props.data.footer
  const content = props.data.insight.internal.content
  const categoryURL = insightMenuItems.find(
    (menuItem) => menuItem.text === category
  )
  if (!categoryURL) {
    throw new Error("Could not find Category URL")
  }
  const processedFeaturedImage = getProcessedImage(featuredImage)
  const { insights, blogDetail } = props.data

  const processedNewsletterImage = getProcessedImage(
    blogDetail.frontmatter.newsletter.sideImage
  )
  const processedInsights = getProcessedInsights(title, insights.nodes)

  const socialPreviewImage = getSrc(processedFeaturedImage)

  return (
    <Layout
      title={`${title} | ${siteData.title}`}
      description={summary}
      imageURL={`${siteData.siteUrl}${socialPreviewImage}`}
      header={getHeaderProps(headerProps, "light")}
      footer={getFooterProps(footerProps)}
    >
      <div style={{ minHeight: "100vh" }}>
        <MaxWidthContainer>
          <PostMenu
            category={{
              name: categoryURL.text,
              link: categoryURL.link,
            }}
            title={title}
          />
        </MaxWidthContainer>
        <Paper color="blue" gradient>
          <HeroContainer>
            <HeroTextContainer>
              <CategoryContainer>
                <CategoryText variant="caption">{category}</CategoryText>
                <AccentDivider />
              </CategoryContainer>
              <Typography variant="h3">{title}</Typography>
              <AuthorContainer>
                <Typography variant="p">Published by</Typography>
                <UnstyledLink href={author.link} target="_blank">
                  <AuthorName variant="p">{author.name}</AuthorName>
                </UnstyledLink>
              </AuthorContainer>
            </HeroTextContainer>
            <FeaturedImageContainer>
              <GatsbyImage
                image={processedFeaturedImage}
                alt={`featured image ${title}`}
              />
            </FeaturedImageContainer>
          </HeroContainer>
        </Paper>
        <MainContainer>
          <ContentContainer>
            <SharePostContainer>
              <SharePost postUrl={props.location.href} title={title} />
            </SharePostContainer>
            <MarkdownContainer>
              <ReactMarkdown
                components={{
                  li: ({ node: _node, ...props }) => <ListItem {...props} />,
                  img: ({ node: _node, ...props }) => <Image {...props} />,
                  aside: ({ node: _node, ...props }) => (
                    <AsideText {...props} />
                  ),
                  code: ({ className, children }) => {
                    const match = /language-(\w+)/.exec(className || "")
                    const language = match ? match[1] : "javascript"
                    return (
                      <SyntaxHighlighter
                        language={language}
                        style={code_block_styles}
                        children={String(children).replace(/\n$/, "")}
                        showLineNumbers
                      />
                    )
                  },
                }}
              >
                {content}
              </ReactMarkdown>
            </MarkdownContainer>
          </ContentContainer>
          <NewsletterSection>
            <NewsletterCard sideImage={processedNewsletterImage} />
          </NewsletterSection>
          <InsightsTitleSection>
            <InsightsTitle variant="h2">
              {blogDetail.frontmatter.moreInsights.title}
            </InsightsTitle>
          </InsightsTitleSection>
          <InsightsContainer>
            {processedInsights.map(
              ({ title, category, summary, featuredImage }) => {
                const link = `/insights/${getSlugFromTitle(title)}`

                return (
                  <BlogSummaryCard
                    key={title}
                    title={title}
                    category={category}
                    description={summary}
                    coverImage={featuredImage}
                    link={link}
                  />
                )
              }
            )}
          </InsightsContainer>
        </MainContainer>
      </div>
    </Layout>
  )
}
const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const HeroContainer = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "100%",
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})

const HeroTextContainer = styled("div")({
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  gap: "3em",
  padding: "4em 0",
})

const FeaturedImageContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
})

const CategoryContainer = styled("div")({
  display: "flex",
  gap: "0.5em",
  alignItems: "center",
})

const ContentContainer = styled("div")(({ theme }) => ({
  color: theme.palette.darkblue.main,
  display: "flex",
  gap: "4em",
}))

const AuthorContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5em",
})

const AuthorName = styled(Typography)({
  fontWeight: 700,
})

const CategoryText = styled(Typography)({
  letterSpacing: "0.32em",
  textTransform: "uppercase",
})

const UnstyledLink = styled("a")({
  textDecoration: "none",
  color: "#fff",
})

const SharePostContainer = styled("div")({
  paddingTop: "25px",
  display: "none",
  [breakpoint("sm")]: {
    display: "block",
  },
})
const MarkdownContainer = styled("div")(({ theme }) => ({
  width: "100%",
  "& > p": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& > h1": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "32px",
    fontWeight: 800,
    lineHeight: "40px",
  },
  "& > h2": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& > h3": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "20",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& > li": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& > img": {
    height: "auto",
    width: "100%",
  },
}))

const ListItem = styled("li")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  lineHeight: "24px",
}))

const AsideText = styled("aside")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  lineHeight: "24px",
}))

const InsightsContainer = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})

const InsightsTitleSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.darkblue.main,
}))

const InsightsTitle = styled(Typography)({
  fontSize: "24px",
  [breakpoint("sm")]: {
    fontSize: "32px",
  },
})

const NewsletterSection = styled(MaxWidthContainer)({
  display: "flex",
  justifyContent: "center",
})

const Image = styled("img")({
  width: "100%",
  height: "auto",
})

const MainContainer = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
  padding: "5em 2em",
  [breakpoint("lg")]: {
    maxWidth: "800px",
  },
})
