import { MenuItem } from "../Menu/Menu"

export const insightMenuItems: Array<MenuItem> = [
  { text: "All Posts", link: "all-posts" },
  { text: "Balanced Analysis", link: "balanced-analysis" },
  { text: "Tutorial", link: "tutorial" },
  { text: "Deep Dive", link: "deep-dive" },
  { text: "Architecture Breakdowns", link: "architecture-breakdowns" },
]
export const getCategory = (url: string) => {
  if (process.env.STORYBOOK) {
    return ""
  }
  const urlWithoutTrailingSlash = url.endsWith("/") ? url.slice(0, -1) : url
  const urlSegments = urlWithoutTrailingSlash.split("/")
  const categoryFromURL = urlSegments[urlSegments.length - 1]
  if (categoryFromURL === "insights") {
    return ""
  }
  const categoryFromList = insightMenuItems.find(
    (menuItem) => menuItem.link === categoryFromURL
  )
  if (categoryFromList) {
    return categoryFromURL
  } else {
    throw new Error("Category not found")
  }
}
