import React from "react"

const TwitterIcon: React.FC<{}> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8002 8.02587C27.8591 8.43768 26.8463 8.71546 25.7836 8.83977C26.8686 8.20031 27.7006 7.18741 28.0929 5.9791C27.078 6.572 25.9545 7.00223 24.7578 7.23448C23.8 6.22976 22.4349 5.60156 20.9242 5.60156C18.024 5.60156 15.6726 7.91639 15.6726 10.772C15.6726 11.1766 15.7188 11.571 15.8087 11.9501C11.4436 11.7342 7.57313 9.67618 4.98263 6.54796C4.53052 7.31121 4.27173 8.19929 4.27173 9.14773C4.27173 10.9413 5.19881 12.5241 6.60812 13.451C5.74757 13.4239 4.93742 13.1911 4.22912 12.8039C4.2286 12.8254 4.2286 12.8474 4.2286 12.8694C4.2286 15.374 6.0391 17.4632 8.442 17.939C8.00133 18.0566 7.53728 18.1201 7.05815 18.1201C6.71933 18.1201 6.39039 18.0878 6.06976 18.027C6.73856 20.0809 8.67793 21.5762 10.9759 21.6176C9.17836 23.0045 6.9142 23.8312 4.4531 23.8312C4.02957 23.8312 3.61125 23.8066 3.2002 23.7585C5.52515 25.2262 8.28559 26.0816 11.2513 26.0816C20.9123 26.0816 26.1946 18.2035 26.1946 11.371C26.1946 11.1469 26.1899 10.9234 26.1801 10.7014C27.2059 9.97391 28.0966 9.06281 28.8002 8.02587Z"
        fill="#FF8E52"
      />
    </svg>
  )
}

const FacebookIcon: React.FC<{}> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7259 11.4893H17.5961V8.83238C17.5961 7.83455 18.2703 7.60192 18.7451 7.60192C19.2189 7.60192 21.6595 7.60192 21.6595 7.60192V3.21532L17.6458 3.19995C13.1903 3.19995 12.1763 6.47162 12.1763 8.5653V11.4893H9.59961V16.0095H12.1763C12.1763 21.8104 12.1763 28.7999 12.1763 28.7999H17.5961C17.5961 28.7999 17.5961 21.7415 17.5961 16.0095H21.2533L21.7259 11.4893Z"
        fill="#FF8E52"
      />
    </svg>
  )
}

const LinkedInIcon: React.FC<{}> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1443 6.28394C10.1443 4.58379 8.76574 3.19995 7.07213 3.19995C5.36867 3.19995 4 4.58379 4 6.28394C4 7.98409 5.36867 9.36794 7.07213 9.36794C8.76574 9.36794 10.1443 7.98409 10.1443 6.28394ZM9.71113 11.7007H4.41367V28.8011H9.71113V11.7007Z"
        fill="#FF8E52"
      />
      <path
        d="M13.0391 11.7003H18.11V14.043H18.179C18.8879 12.6987 20.6111 11.2852 23.1909 11.2852C28.5474 11.2852 29.5419 14.8238 29.5419 19.4301V28.8007H24.2543V20.4877C24.2543 18.5009 24.2248 15.9507 21.5071 15.9507C18.7501 15.9507 18.3365 18.1154 18.3365 20.3394V28.8007H13.0489V11.7003H13.0391Z"
        fill="#FF8E52"
      />
    </svg>
  )
}

const EmailIcon: React.FC<{}> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0721 7.13831H5.92784C4.9663 7.13831 4.18457 7.96907 4.18457 8.99023V23.0095C4.18457 24.0306 4.9663 24.8614 5.92784 24.8614H26.0721C27.0336 24.8614 27.8153 24.0306 27.8153 23.0095V8.99023C27.8153 7.96907 27.0336 7.13831 26.0721 7.13831ZM18.6256 16.0001L26.3384 10.4338V21.5659L18.6256 16.0001ZM26.0721 8.61523C26.1248 8.61523 26.1782 8.64708 26.2246 8.69468L16 16.0738L5.77532 8.69468C5.82171 8.64708 5.87514 8.61523 5.92784 8.61523H26.0721ZM5.66149 21.5659V10.4338L13.3738 15.9998L5.66149 21.5659ZM5.92784 23.3845C5.87514 23.3845 5.82171 23.3525 5.77532 23.305L14.6356 16.9104L15.5682 17.5835C15.5903 17.5995 15.6159 17.6061 15.6393 17.6193C15.662 17.6322 15.6833 17.6432 15.7072 17.6536C15.8016 17.6949 15.8997 17.7229 15.999 17.7229C15.9994 17.7229 15.9996 17.7227 16 17.7227C16.0003 17.7227 16.0006 17.7229 16.0009 17.7229C16.1003 17.7229 16.1983 17.6949 16.2927 17.6536C16.3166 17.6432 16.3379 17.6322 16.3607 17.6193C16.384 17.6061 16.4096 17.5995 16.4317 17.5835L17.3638 16.9107L26.2246 23.305C26.1782 23.3525 26.1248 23.3845 26.0721 23.3845H5.92784Z"
        fill="#FF8E52"
      />
    </svg>
  )
}

const generateTwitterURL = (url: string, title: string): string => {
  return `https://twitter.com/intent/tweet?url=${url}&text=${title}`
}

const generateFacebookURL = (url: string, _title: string): string => {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}`
}

const generateLinkedInURL = (url: string, _title: string): string => {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
}

const generateEmailURL = (url: string, title: string): string => {
  return `mailto:?subject=${title}&body=Check out this article ${url}`
}

interface SharingSource {
  name: string
  icon: React.ElementType
  urlGenerator: (postUrl: string, title: string) => string
}

export const sharingSources: Array<SharingSource> = [
  {
    name: "Facebook",
    icon: FacebookIcon,
    urlGenerator: generateFacebookURL,
  },
  {
    name: "Twitter",
    icon: TwitterIcon,
    urlGenerator: generateTwitterURL,
  },
  {
    name: "Email",
    icon: EmailIcon,
    urlGenerator: generateEmailURL,
  },
  {
    name: "LinkedIn",
    icon: LinkedInIcon,
    urlGenerator: generateLinkedInURL,
  },
]
