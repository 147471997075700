import styled from "@emotion/styled"
import { GatsbyLinkProps } from "gatsby"
import React from "react"
import { PlainLink } from "../PlainLink/PlainLink"

interface PostMenuProps {
  title: string
  category: {
    name: string
    link: string
  }
}
export const PostMenu: React.FC<PostMenuProps> = ({ title, category }) => {
  return (
    <nav aria-label="Post Stepper Menu">
      <MenuList>
        <li>
          <MenuLink to="/insights">Insights</MenuLink>
        </li>
        <li>&#x203A;</li>
        <li>
          <MenuLink to={`/insights/${category.link}`}>{category.name}</MenuLink>
        </li>
        <li>&#x203A;</li>
        <li>
          <MenuLink to="#" active>
            {title}
          </MenuLink>
        </li>
      </MenuList>
    </nav>
  )
}

const MenuList = styled.ul({
  display: "flex",
  listStyleType: "none",
  gap: "1em",
  padding: "0",
  overflow: "auto",
  whiteSpace: "nowrap",
})

const MenuLink = styled(PlainLink)<GatsbyLinkProps<any> & { active?: boolean }>(
  ({ theme, active }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: active ? 600 : 400,
    color: active ? "#000000" : "rgba(0,0,0,0.5)",
  })
)
