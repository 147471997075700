import { graphql } from "gatsby"
import { BlogPost } from "../BlogPost/BlogPost"

export const query = graphql`
  query ($fileAbsolutePath: String!) {
    insight: markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        title
        category
        summary
        author {
          name
          title
          link
        }
        date
        featuredImage {
          childImageSharp {
            gatsbyImageData(height: 350)
          }
        }
      }
      internal {
        content
      }
    }
    blogDetail: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/insightDetail.md/" }
    ) {
      frontmatter {
        newsletter {
          sideImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        moreInsights {
          title
        }
      }
    }
    insights: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/blog/" } }
    ) {
      nodes {
        frontmatter {
          title
          category
          summary
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`

export default BlogPost
